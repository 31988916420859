
var close = false;
$( ".close-side" ).click(function(e) {
    e.preventDefault();
    if(!close){
        $(this).addClass("toOpen");
        $(".aside-right").addClass("closeAsideright");
        close = true;
    }
    else{
        $(this).removeClass("toOpen");
        $(".aside-right").removeClass("closeAsideright");
        close = false
    }
});
$( ".open-menu" ).click(function(e) {
    e.preventDefault();
    if( !$(this).hasClass("toClose")){
        $(this).addClass("toClose");
        $(".menu").addClass("active");
    }
    else{
        $(this).removeClass("toClose");
        $(".menu").removeClass("active");

    }
});
$( ".open-extra" ).click(function(e) {
    e.preventDefault();
    if( !$(this).hasClass("toClose")){
        $(this).addClass("toClose");
        $(".subevent .content__left").addClass("active");
    }
    else{
        $(this).removeClass("toClose");
         $(".subevent .content__left").removeClass("active");

    }
});