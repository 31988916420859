import "lazysizes";
import Scrollbar from 'smooth-scrollbar';
import BezierEasing from "bezier-easing";


var aniComplete = true;

var hide = false;
if ($(".scrollContent").length > 0) {


  if (!viewportIsMobile($("body").outerWidth())) {
    var vdamping = 0.1;
    let scrollbars = {};
    scrollbars['root'] = Scrollbar.init($(".scrollContent")[0], {
      alwaysShowTracks: true,
      damping: vdamping
    });
    let frameSwipe = {
      name: 'root',
      obj: $("#scrollContent").children(".scroll-content"),
      total: 6,
      frames: ['.section--1', '.section--2', '.section--3', '.section--4', '.section--5', '.section--6'],
      navigation: '.navigation',
      index: 0,
      scrollTop: 0,
      scrollTopCurrent: 0,
      animateDelay: 400,
      animateEndDelay: 400,
      isEndAnimate: true
    }
    scrollbars['root'].addListener(function (status) {
      if (status.offset.y > 800 && !hide) {
        $(".close-side").click();
        hide = true;
      }

      if ($(".outer").hasClass("home")) {
        if (status.offset.y < 800 - 500 * $(window).outerHeight() / 1100) {
          frameSwipe.index = 0;
        }
        else if (status.offset.y >= 800 - 500 * $(window).outerHeight() / 1100
          && status.offset.y < $('.section--2')[0].offsetTop + $('.section--2')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
        ) {
          frameSwipe.index = 1;
        }
        else if (status.offset.y >= $('.section--2')[0].offsetTop + $('.section--2')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
          && status.offset.y < $('.section--3')[0].offsetTop + $('.section--3')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
        ) {
          frameSwipe.index = 2;
        }
        else if (status.offset.y >= $('.section--3')[0].offsetTop + $('.section--3')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
          && status.offset.y < $('.section--4')[0].offsetTop + $('.section--4')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
        ) {
          frameSwipe.index = 3;
        }
        else if ($('.section--4')[0].offsetTop + $('.section--4')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
          && status.offset.y < $('.section--5')[0].offsetTop + $('.section--5')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
        ) {
          frameSwipe.index = 4;
        }
        else if ($('.section--5')[0].offsetTop + $('.section--5')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
          && $('.section--6')[0].offsetTop + $('.section--6')[0].offsetHeight - 500 * $(window).outerHeight() / 1100
        ) {
          frameSwipe.index = 5;
        }
        $(".menu .menu-tab").removeClass("active");
        $(".menu .menu-tab").eq(frameSwipe.index).addClass("active");

      }



    });
    $(".scrollLink").each(function () {
      $(this).on("click", function (e) {
        e.preventDefault();
        if (frameSwipe.isEndAnimate) {
          frameSwipe.isEndAnimate = false;
          var frameName = $(this).data("scroll-link");
          frameSwipe.index = frameSwipe.frames.indexOf(frameName);
          var _tempScroll = 0;
          if ($(this).data("scroll-link") == ".section--5") {
            _tempScroll = 500 * $(window).outerHeight() / 1100;
          }
          else {
            _tempScroll = 100 * $(window).outerHeight() / 1100;
          }
          scrollbars['root'].scrollTo(0, $($(this).data("scroll-link"))[0].offsetTop - _tempScroll, frameSwipe.animateDelay, {
            easing: (p) => {
              var easing = BezierEasing(0.86, 0, 0.07, 1);
              return easing(p);
            },
            callback: () => {
              frameSwipe.isEndAnimate = true;
              $(".scrollLink").removeClass("active");
              $(this).addClass("active");
            }
          });
        }

      })
    });
    $(".scrollTop").each(function () {
      $(this).on("click", function (e) {
        e.preventDefault();
        if (frameSwipe.isEndAnimate) {
          frameSwipe.isEndAnimate = false;
          frameSwipe.index = 0;
          scrollbars['root'].scrollTo(0, 0, frameSwipe.animateDelay, {
            easing: (p) => {
              var easing = BezierEasing(0.86, 0, 0.07, 1);
              return easing(p);
            },
            callback: () => {
              frameSwipe.isEndAnimate = true;
              $(".scrollLink").removeClass("active");
              $(".scrollLink").eq(0).addClass("active");
            }
          });
        }
      })
    });



  }
  else {
    $(".scrollLink").each(function () {
      $(this).on("click", function (e) {
        e.preventDefault();
        $(".scrollLink").removeClass("active");
        $(this).addClass("active");
        if (aniComplete == true) {
          aniComplete = false;
          $("body,html").animate({
            scrollTop: $($(this).data("scroll-link")).offset().top
          }, 600, function () {
            aniComplete = true;
          });

        }
      })
    });
  }

}
if ($(".scrollArticle").length > 0) {
  scrollbars['article'] = Scrollbar.init($(".scrollArticle")[0], {
    alwaysShowTracks: true
  })
}
if ($(".scrollPopup").length > 0) {
  scrollbars['popup'] = Scrollbar.init($(".scrollPopup")[0], {
    alwaysShowTracks: true
  })
}

function viewportIsMobile(width) {
  return (width < 1025);
}

// import Swiper from 'Swiper';










import Swiper from 'Swiper';
import 'swiper/css/swiper.min.css';

if ($('.banner-event').length > 0) {
  initSlideThumb('banner-event');
}

function initSlideThumb(id) {
  var selector = '.' + id;
  var hloop;
  var arraySlide = new Object();
  var gallery = new Object();
  var sliden;
  if ($("body").outerWidth() < 1025) {
    sliden = 1;
    hloop = 1;
  }
  else {
    sliden = 4;
    hloop = 4;
  }
  gallery.top = new Swiper(selector + ' .swiper-banner', {
    autoplay: {
      delay: 9000,
    },
    speed: 600,
    disableOnInteraction: true,
    preventClicks: false,
    loop: true,
    loopedSlides: hloop,
    navigation: {
      nextEl: '.banner-event-next',
      prevEl: '.banner-event-prev',
    }, //looped slides should be the same
  });

  gallery.thumbs = new Swiper(selector + ' .swiper-banner-title', {
    slidesPerView: sliden,
    touchRatio: 0.2,
    direction: 'vertical',
    loop: true,
    loopedSlides: hloop, //looped slides should be the same
    slideToClickedSlide: true,
    disableOnInteraction: true,

    // preventClicks: true,
    preventClicks: false,
    // freeMode: true,
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
  });

  arraySlide.id = gallery;
  arraySlide.id.top.controller.control = arraySlide.id.thumbs;
  arraySlide.id.thumbs.controller.control = arraySlide.id.top;

}

if ($('.pet-system').length > 0) {
  var swiperpetbullet = new Swiper('.swiper-pet-bullet', {
    grabCursor: true,
    centeredSlides: true,

    pagination: {
      el: '.swiper-pagination--pet',
      clickable: true,
    },
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    slidesPerView: 1,

  });
  var myDelay = 5000;
  var thisDelay = 5000;
  var start = Date.now();
  let indexpet = 1;
  let nPet = $(".pet-info--item").length;
  let currentSlide = 1;
  function startTimer() {
    setTimeout(function () {
      if (indexpet == nPet) {
        indexpet = 1;
      }
      else {
        indexpet++;
      }
      if (indexpet % 6 == 1) {
        currentSlide = parseInt(indexpet / 6) ;
        swiperpetbullet.slideTo(currentSlide);
      }


      $(".pet-info--item").removeClass("active");
      $(".btn-choose-pet").removeClass("active");

      $(".pet-info--item-" + indexpet).addClass("active");
      $(".btn-choose-pet-" + indexpet ).addClass("active");


      // calculate the actual number of ms since last time
      var actual = Date.now() - start;
      // subtract any extra ms from the delay for the next cycle
      thisDelay = myDelay - (actual - myDelay);
      start = Date.now();
      // start the timer again
      startTimer();
    }, thisDelay);
  }
  startTimer();
  $(".btn-choose-pet").click(function(e) {
    e.preventDefault();
   
    $(".btn-choose-pet").removeClass("active");
    $(this).addClass("active");
  
    $(".pet-info--item").removeClass("active");
    $($(this).data('target')).addClass("active");
    indexpet = parseInt($(this).data('target').split(".pet-info--item-")[1]);
    currentSlide = swiperpetbullet.realIndex;
  
  });
}



if ($('.swiper-vu-khi').length > 0) {
  var swiper_vukhi_n;
  var spaceBetween_vk;
  if ($("body").outerWidth() < 1025) {
    swiper_vukhi_n = 3;
    spaceBetween_vk = 10;
  }
  else {
    swiper_vukhi_n = 5;
    spaceBetween_vk = 0;
  }
  var swiper_vukhi = new Swiper('.swiper-vu-khi', {
    slidesPerView: swiper_vukhi_n,
    spaceBetween: spaceBetween_vk,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.vk-next',
      prevEl: '.vk-prev',
    },
    autoplay: {
      delay: 6000,
    },

  });
}