var MsVportal = {};

import twbsPagination from "./_vendor-twbspagination"

var loadingHTML = '<p class="posts__loading"><span>Đang tải dữ liệu...</span></p>';

var currentSection;
var shortUrl;
jQuery(document).ready(function(e) {

    (function($) {
        var cate = $.urlParam('post');
       

        if (cate != null && cate.length > 0) {
            MsVportal.loadNews(1, 'loadTab', cate);
        }
        var shortUri = $('#shortUri').val();
        var itemTotal = $('#itemTotal').val();

        if (itemTotal > 0) {
            if ($('#pagination-container').length > 0) {
                MsVportal.pagination();
            }
        }

        // $('.sub #posts__tab li a').click(function(event) {
        //     $('#posts__tab a').removeClass('active');
        //      currentSection = $(this).data('target');
        //     $(this).addClass('active');
        //     MsVportal.loadNews(1, 'loadTab', currentSection);
        //     return false;
        // });
        $('#posts__tabs li a').click(function(event) {
    
            $('#posts__tabs li a').removeClass('active');
            $(this).addClass('active');
            MsVportal.loadNews(1, 'loadTab', $(this).data('cate'));
            return false;
        });
        
        $('#gallery-tabs li a').click(function(event) {
         
            var currentTab = "";

            currentTab = $(this).data('category');

            shortUrl = $(this).data('shorturl') + '/' + $(this).data('block-name');
            if ($('body').hasClass('subpage')) {
                // subpage dont have /
                shortUrl = domain + '/thu-vien-ajax/' + $(this).data('block-name');
            }
            shortUrl += currentTab.length > 0 ? '.1.html?cate=' + currentTab : '.1.html';

            $("#gallery-tabs li a").removeClass("active");
            $(this).addClass("active");

            MsVportal.loadGallary(currentTab, shortUrl);
            // var x = domain + '/' + $("#section").val() + '/danh-sach.1.html?cate=' + currentTab;
            // $("#list__image-more").attr('href', x);
            return false;

        });


    })(jQuery);

});


MsVportal = (function($) {

    return {

        pagination: function() {

            var itemTotal = $('#itemTotal').val();
            var itemPerPage = $('#itemPerPage').val();
            var currentSection = $('#currentSection').val();
            var modulusPage = itemTotal % itemPerPage > 0 ? 1 : 0;

            var totalPages = parseInt(itemTotal / itemPerPage) + modulusPage;

            var searchPage = 1;

            if ($('#resultSearch').val() == 1) {
                searchPage = parseInt($('#searchPage').val()) + 1;
            }

            if (totalPages > 1) {

                $('#pagination').twbsPagination({
                    startPage: searchPage,
                    totalPages: totalPages,
                    visiblePages: 5,
                    first: '&laquo;',
                    prev: '&lsaquo;',
                    next: '&rsaquo;',
                    last: '&raquo;',
                    onPageClick: function(event, page) {

                        if ($('#resultSearch').val() == 1) {
                            $('#searchPage').val(page - 1);
                            $('#custom-search-form').submit();
                        } else {
                            MsVportal.loadNews(page, 'paging', currentSection);
                        }
                    }
                });

            } else {

                $('#pagination').empty();
                $('#pagination').removeData("twbs-pagination");
                $('#pagination').unbind("page");

            }

        },

        loadNews: function(page, type, currentSection) {

            var shortUri = $('#shortUri').val();
            var container = $('#posts__list');
            var loading = container.prev('.posts__loading');
            var urlRequest = currentSection.length > 0 ? shortUri + '.' + page + '.html?cate=' + currentSection : shortUri + '.' + page + '.html';

            if($('#urlThuVienHinh').val() == 1) {
                url = shortUri.replace("00", page);
                
            }
            $.ajax({
                url: urlRequest,
                dataType: 'json',
                success: function(result) {

                    $('#posts__list').html(result);

                    if (type == 'loadTab') {

                        $('#pagination').empty();
                        $('#pagination').removeData("twbs-pagination");
                        $('#pagination').unbind("page");

                        if ($('#itemTotal').val() > 0) {
                            MsVportal.pagination();
                        }
                        $('#currentSection').val(currentSection);
                        if ($('body').hasClass('subpage')) {
                            // rewrite url to active true tab
                            history.pushState(null, null, domain + "/posts-2020.html?post=" + currentSection);
                            $('#posts__tabs li a').removeClass('active');
                            $('#posts__tabs li a[data-cate = "' + currentSection + '"]').closest('li').addClass('active');
                        }
                        if ($('#posts__view-all').length > 0) {
                            $('#posts__view-all').attr('href', domain + '/posts-2020.html?post=' + currentSection);
                        }
                    }
                }
            });
        },
        loadGallary: function(cate, shortUrl) {
            var container = $('#list-image');
            var loading = container.prev('.posts__loading');

            $.ajax({
                url: shortUrl,
                dataType: 'html',
                beforeSend: function() {

                    if (loading.length == 0) {
                        loading = $(loadingHTML);
                        container.before(loading);
                    } else {
                        loading.removeClass('hidden')
                    }

                },
                success: function(result) {
                    loading.addClass('hidden');
                    if (result.length > 0) {
                        container.html(result);
                    } else {
                        container.html("Dữ liệu đang cập nhật");
                    }
                }
            });
        }

        
    }

})(jQuery);

$.urlParam = function(name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        return null;
    } else {
        return results[1] || 0;
    }
}


/* prototype parse string to boolean */
String.prototype.parseBool = function() {
    return (/^true$/i).test(this);
};
