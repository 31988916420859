$('.btn-search-field').click(function(event) {
    event.preventDefault();
    if($(".posts__search").hasClass("active")){
        $(".posts__search").removeClass("active");
    }
    else{
        $(".posts__search").addClass("active");
    }
 
});
$(".posts__search form").on('keypress',function(e) {
    if(e.which == 13) {
       $(".btn-search").click();
    }
});