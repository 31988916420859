import "./components/_scaleRoot";
$(".inner").scaleRoot({
    scaleMode: (viewportIsMobile($("body").outerWidth())) ? 'containWidth': 'cover'
});  

function viewportIsMobile(width){
    return (width < 1025);
}



import "./components/_lightbox";

// import "./components/_ajax-news-home";
import "./components/_ajax-load-news";



import MobileDetect from "mobile-detect"

var md = new MobileDetect(window.navigator.userAgent);
console.log( md.os() );              // 'AndroidOS'

if ($(".getLink").length > 0) {

    switch (md.os()) {
        case 'AndroidOS': 
            $(".getLink").attr("href", $(".getLink-android").attr("href"));
            break;
        case 'iOS':
            $(".getLink").attr("href", $(".getLink-ios").attr("href"));
            break;
        default: 
    }
    
}


import "./components/_content-tab";
import "./components/_control-pet";

import "./components/_control-side";
import "./components/_search";






