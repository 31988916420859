
var target;
var control;


$(".home .tab-item").click(function(e) {
    e.preventDefault();
    target =  $(this).data('target');
    control =  $(this).data('control');
    
    $("."+control+"__tab-item").removeClass("active");
    $(this).addClass("active");
  
    $("."+control+"--item").removeClass("active");
    $(target).addClass("active");

});






